"use client";

import { Auth0Provider } from "@equipmentshare/auth0-react";
import { EuiLoadingSpinner } from "@equipmentshare/ds2";
import { useRouter } from "next/navigation";
import { ReactNode } from "react";

import env from "@/env";

export type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = (props: AuthProviderProps) => {
  const router = useRouter();

  return (
    <Auth0Provider
      audience={env.authenticationOptions.audience}
      clientId={env.authenticationOptions.clientId}
      domain={env.authenticationOptions.domain}
      onRedirectCallback={(appState) => {
        router.replace(appState?.returnTo || "/");
      }}
      redirectUri={env.authenticationOptions.redirectUri!}
      scope={env.authenticationOptions.scope}
      withAuthenticationRequiredOptions={{
        onRedirecting: () => (
          <div
            style={{
              display: "grid",
              placeItems: "center center",
              width: "100vw",
              height: "100vh",
            }}
          >
            <EuiLoadingSpinner size="xl" />
          </div>
        ),
      }}
    >
      {props.children}
    </Auth0Provider>
  );
};
