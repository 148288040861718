import {
  NotificationsDocument,
  useSubscription,
} from "@fleet-configuration/client";
import React, { type ReactNode } from "react";

import eventEmitter from "@/app/eventEmitter";

const NotificationProvider = (props: { children: ReactNode }) => {
  useSubscription(NotificationsDocument, {
    onData: ({ data }) => {
      const notification = data?.data?.notification;
      if (notification) {
        eventEmitter.emit(notification.notificationType, notification);
      }
    },
  });
  return <>{props.children}</>;
};

export default NotificationProvider;
