"use client";

import {
  CLIENT_CONSTANTS,
  FleetConfigurationClientProvider,
  FleetConfigurationClientProviderConfig,
} from "@fleet-configuration/client";
import {
  FleetConfigurationComponentProvider,
  FleetConfigurationComponentProviderConfig,
} from "@fleet-configuration/components";
import { App, Heaper } from "@fleet-configuration/heaper-utils";
import { ReactNode, useEffect, useState } from "react";

import { AuthProvider } from "@/app/providers/auth-provider";
import { DeviceInstallStatusProvider } from "@/app/providers/DeviceInstallStatusProvider";
import NotificationProvider from "@/app/providers/NotificationProvider";
import { CONSTANTS } from "@/config/constants";
import { PendingAssignmentsProvider } from "@/contexts";
import env from "@/env";

export interface ProvidersProps {
  children: ReactNode;
}

const fleetConfigurationClientProviderConfig: FleetConfigurationClientProviderConfig =
  {
    environment: env.environment,
    endpoint: CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_PATH,
    notificationsEndpoint:
      CLIENT_CONSTANTS.FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH,
  };

const fleetConfigurationComponentProviderConfig: FleetConfigurationComponentProviderConfig =
  {
    flyout: {
      keys: Object.keys(CONSTANTS.FlyoutContextKey),
    },
  };

export function Providers({ children }: ProvidersProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Making sure the hooks have run before rendering the provider and children is
  // a work around to ensure a) EuiComponents that reference browser-only APIs are not rendered on
  // server side, and b) make sure there is no flash of unstyled content in the browser.
  return mounted ? (
    <AuthProvider>
      <FleetConfigurationClientProvider
        config={fleetConfigurationClientProviderConfig}
      >
        <FleetConfigurationComponentProvider
          config={fleetConfigurationComponentProviderConfig}
        >
          <Heaper appName={App.FLEET_CONFIGURATION_DASHBOARD} />
          <DeviceInstallStatusProvider>
            <PendingAssignmentsProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </PendingAssignmentsProvider>
          </DeviceInstallStatusProvider>
        </FleetConfigurationComponentProvider>
      </FleetConfigurationClientProvider>
    </AuthProvider>
  ) : null;
}
